/**
 * APIドメイン定義.
 */

/** APIドメインを取得. */
const API_DOMAIN = (window.location.origin === "https://imagination.m-78.jp") ? "https://if.imagination.m-78.jp"
    : ((window.location.origin === "https://stg.imagination.m-78.jp") ? "https://stg-if.imagination.m-78.jp"
    : "https://dev-if.imagination.m-78.jp");

/** FILEドメインを取得. */
const FILE_DOMAIN = (window.location.origin === "https://imagination.m-78.jp") ? "https://imagination.m-78.jp"
    : ((window.location.origin === "https://stg.imagination.m-78.jp") ? "https://stg.imagination.m-78.jp"
    : "https://dev.imagination.m-78.jp");

/** accountif. */
export const ACCOUNTIF = API_DOMAIN + "/account";

/** authif. */
export const AUTHIF = API_DOMAIN + "/auth";

/** contentif. */
export const CONTENTIF = API_DOMAIN + "/content";

/** contractif. */
export const CONTRACTIF = API_DOMAIN + "/contract";

/** deliveryheif. */
export const DELIVERYHEIF = API_DOMAIN + "/delivery/user";

/** eventif. */
export const EVENTIF = API_DOMAIN + "/event";

/** recommendif. */
export const RECOMMENDIF = API_DOMAIN + "/recommend";

/** sessionif. */
export const SESSIONIF = API_DOMAIN + "/session";

/** ファイル：プラン料金. */
export const FILE_PLAN = API_DOMAIN + "/conf/meta_sub/plan.json";

/** ファイル：汎用設定ファイル（app_config_web）取得. */
export const FILE_APP_CONFIG_WEB = API_DOMAIN + "/conf/app_config/app_config_web.json";

/** ファイル：プロフィール画像. */
export const FILE_PROFILE_PICTURE = API_DOMAIN + "/conf/profile_image/list.json";

/** ファイル：TOP定義. */
export const FILE_TOP = API_DOMAIN + "/conf/top/";

/** ファイル：プラネット定義. */
export const FILE_PLANET = API_DOMAIN + "/conf/planet/planet_v4.json";

/** ファイル：ライブラリ定義ファイル取得. */
export const FILE_DEFINITION = API_DOMAIN + "/conf/library/";

/** ファイル：静的コンテンツ. */
export const FILE_TEMPLATE = FILE_DOMAIN + "/templates/";

/** 決済サーバードメイン. */
export const PAYMENT_DOMAIN = API_DOMAIN + "/payment/request";

/** プロフィール画像. */
export const PROFILE_PICTURE = FILE_DOMAIN + "/common/profile/";

/** ファイル：ライブラリランキングメタ定義ファイル取得. */
export const FILE_RANKING_META = API_DOMAIN + "/conf/library/meta/ranking_meta.json";
