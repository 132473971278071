import * as imageQuality from "./imageQuality";

/**
 * 動画プレイヤーの定数.
 */

/** ポーリング間隔. */
export const POLLING_INTERVAL = 60000;

/** マウスカーソル非表示間隔. */
export const HIDE_CURSOR_INTERVAL = 5000;

/** AES(clear-key). */
export const CLEAR_KEY = 1;

/** DRM(Widevine). */
export const WIDEVINE = 2;

/** DRM(FairPlay). */
export const FAIR_PLAY = 4;

/** DRM(PlayReady). */
export const PLAY_READY = 3;

/** 待機時間（秒） */
export const INITIAL_WAITING_COUNT = 5;

/** MediaSDK用Plugin初期化用JSON取得URL */
export const INIT_JSON_URL = (window.location.origin === "https://dev.imagination.m-78.jp") ? "https://dev.qoe-msdk-dcm-cddd.com/tsuburaya/html5/latest/init.json"
: "https://qoe-msdk-dcm-cddd.com/tsuburaya/html5/latest/init.json";

/** MediaSDK用QoE機能設定ファイル取得URL */
export const KEYS_INI_URL = (window.location.origin === "https://dev.imagination.m-78.jp") ? "/assets/scripts/mediasdk/qoe/develop/keys.text"
: "/assets/scripts/mediasdk/qoe/production/keys.text";

/**
 * 画質変更時の再生URLを取得する.
 * qualityには画質(1080, 480等)又は、HD・SDを指定する.
 */
export const getPlayUrl = (playUrl, quality) => {
    const newPlayUrl = new URL(playUrl);
    if (quality === "HD") {
        newPlayUrl.searchParams.set("resolution", "1080");
        newPlayUrl.searchParams.delete("fixed");
    }
    else if (quality === "SD" || quality === "DATASAVER") {
        newPlayUrl.searchParams.set("resolution", "480");
        newPlayUrl.searchParams.delete("fixed");
    }
    else {
        newPlayUrl.searchParams.set("resolution", quality);
    }
    return newPlayUrl.href;
}

/**
 * FairPlayのライブ再生URLを取得する.
 * bitrateにはビットレート又は、HD・SD・DATASAVERを指定する.
 */
export const getLiveFairPlayUrl = (playUrl, bitrate) => {
    let newPlayUrl = new URL(playUrl);
    newPlayUrl.searchParams.delete("resolution");
    newPlayUrl.searchParams.delete("aws.manifestfilter");
    newPlayUrl = newPlayUrl.href;
    const videoBitrate = "?aws.manifestfilter=video_bitrate:0-"
    if (bitrate === "HD") {
        newPlayUrl += videoBitrate + imageQuality.BITRATE_1080;
    }
    else if (bitrate === "SD" || bitrate === "DATASAVER") {
        newPlayUrl += videoBitrate + imageQuality.BITRATE_480;
    }
    else {
        newPlayUrl += videoBitrate + bitrate;
    }
    return newPlayUrl;
}
