/** お気に入り一覧. */
export const FAVORITE = "favorite";

/** 視聴中の作品一覧. */
export const RESUME = "resume";

/** レンタル・取得した作品一覧. */
export const PURCHASE = "purchase";

/** ピックアップコンテンツ一覧. */
export const PICKUP = "pickup";

/** おすすめのコンテンツ一覧. */
export const RECOMMEND = "recommend";

/** グッズコンテンツ一覧. */
export const GOODS = "goods";

/** 新着コンテンツ一覧. */
export const NEW_ARRIVAL = "newArrival";

/** ライブ・イベントコンテンツ一覧. */
export const EVENTS = "events";

/** ニュースコンテンツ一覧. */
export const NEWS = "news";

/** みんなが見ているコンテンツ一覧. */
export const RANKING = "ranking";

/** 連載コンテンツ一覧. */
export const SERIES = "series";

/** 無料特集一覧. */
export const PICKUP_FREE = "pickup_f";

/** 無料新着一覧. */
export const NEW_ARRIVAL_FREE = "newArrival_f";

/** プレミアム特集一覧. */
export const PICKUP_PREMIUM = "pickup_p";

/** プレミアム新着一覧. */
export const NEW_ARRIVAL_PREMIUM = "newArrival_p";

/** シリーズメタ. */
export const SERIES_META = "SERIES_META";

/** プラネット遷移. */
export const PLANET = "PLANET";

/** 遷移元TOP. */
export const TOP = "top";

/** 遷移元映像詳細. */
export const VIDEO = "video";

/** 遷移元読み物詳細. */
export const TEXT = "text";

/** 遷移元検索結果0件. */
export const SEARCH0 = "search0";

/**
 * ライブラリID判定.
 */
export const isLibraryId = (id) => {
    switch (id) {
        case FAVORITE:
        case RESUME:
        case PURCHASE:
        case PICKUP:
        case GOODS:
        case NEW_ARRIVAL:
        case EVENTS:
        case NEWS:
        case RANKING:
        case SERIES:
        case PICKUP_FREE:
        case NEW_ARRIVAL_FREE:
        case PICKUP_PREMIUM:
        case NEW_ARRIVAL_PREMIUM:
            return true;
        default:
            return false;
    }
}

/**
 * ライブラリ定義判定.
 */
export const isDefinitionFile = (id) => {
    switch (id) {
        case PICKUP:
        case RECOMMEND:
        case GOODS:
        case NEW_ARRIVAL:
        case EVENTS:
        case NEWS:
        case RANKING:
        case SERIES:
        case PICKUP_FREE:
        case NEW_ARRIVAL_FREE:
        case PICKUP_PREMIUM:
        case NEW_ARRIVAL_PREMIUM:
            return true;
        default:
            return false;
    }
}

/**
 * タイトルを取得.
 */
export const getTitle = (id) => {
    switch (id) {
        case FAVORITE:
            return "お気に入り";
        case RESUME:
            return "視聴中の作品";
        case PURCHASE:
            return "レンタル・取得した作品一覧";
        case PICKUP:
            return "ピックアップ";
        case RECOMMEND:
            return "あなたへのおすすめ";
        case GOODS:
            return "グッズ";
        case NEW_ARRIVAL:
            return "コンテンツ";
        case EVENTS:
            return "ライブ・イベント";
        case NEWS:
            return "ニュース";
        case RANKING:
            return "人気コンテンツ";
        case SERIES:
            return "連載";
        case PICKUP_FREE:
        case NEW_ARRIVAL_FREE:
            return "無料のコンテンツ";
        case PICKUP_PREMIUM:
        case NEW_ARRIVAL_PREMIUM:
            return "プレミアムのコンテンツ";
        default:
            return "あなたへのおすすめ";
    }
}
