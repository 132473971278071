import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Parallax from "react-rellax";

import * as plan from "../../../constants/plan";
import * as libraryId from "../../../constants/libraryId";
import * as contentsKey from "../../../constants/contentsKey";
import * as app from "../../../constants/app";

import DefaultList from "../../contents/defaultList/DefaultList";
import Separator from "../../parts/separator/Separator";

import * as commonActions from "../../../common/status/actions";
import * as screenActions from "../../../common/status/screen/actions";
import { changeGridList } from "../../../common/status/grid/actions";
import * as actions from "./actions";
import * as contentif from "../../../middleware/contentif";
import * as contractif from "../../../middleware/contractif";
import * as fileApi from "../../../middleware/file";
import * as recommendif from "../../../middleware/recommendif";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import { decode } from "js-base64";
import * as historys from "../../../historys";
import { LIBRARY_ID_FAILURE_TITLE, ULTRA_SUBSC } from "../../../constants/title";
import { setHeadDefault } from "../../../constants/sns";
import * as recommendConstants from "../../../constants/recommend";
import useRecommendCookie from "../../../hooks/useRecommendCookie";

/**
  * ライブラリ一覧画面.
 */
const LibraryId = () => {
    /** Hooks. */
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    /** GridボタンDOM. */
    const iconGridRef = useRef();
    /** ListボタンDOM. */
    const iconListRef = useRef();

    /** ライブラリID. */
    const [libId, setLibId] = useState("");
    /** 検索実行フラグ. */
    const [isSearch, setIsSearch] = useState(true);
    /** 画面表示件数. */
    const [rowCount, setRowCount] = useState(contentif.ROW_DEFAULT);
    /** シリーズ検索用ID. */
    const [seriesId, setSeriesId] = useState("");
    /** ライブラリ検索コンテンツ. */
    const [libContents, setLibContents] = useState([]);
    /** プラネット検索コンテンツ. */
    const [planetContents, setPlanetContents] = useState([]);

    /** シリーズタイトル. */
    const seriesTitle = useSelector(state => state.LibraryId.seriesTitle);
    /** コンテンツリスト. */
    const contentsList = useSelector(state => state.LibraryId.contentsList);
    /** 特典コンテンツリスト. */
    const giftContentsList = useSelector(state => state.LibraryId.giftContentsList);
    /** 会員ID. */
    const memberId = useSelector(state => state.Member.memberId);
    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** お気に入り一覧. */
    const favoriteList = useSelector(state => state.Member.favoriteList);
    /** 視聴中一覧. */
    const resumeList = useSelector(state => state.Member.resumeList);
    /** 購入一覧. */
    const purchaseList = useSelector(state => state.Member.purchaseList);
    /** 特典一覧. */
    const giftList = useSelector(state => state.Member.giftList);
    /** レコメンドクリック情報. */
    const clickData = useSelector(state => state.LibraryId.clickData);

    const { recommendCookie } = useRecommendCookie();

    /** グリッド表示/リスト表示. */
    const isGrid = useSelector(state => state.Grid.isGrid);

    /**
     * ページの高さ（ブラウザ間のページ全体の高さをカバーする）.
     */
    const scrollHeight = () => {
        return Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
    }

    /**
     * コンテンツ取得失敗.
     */
    const requestFailure = useCallback((err) => {
        dispatch(commonActions.updateConectionError(true));
    }, [dispatch]);

    /**
     * レコメンドリクエスト失敗.
     */
    const requestRecommendFailure = useCallback((err) => {
        dispatch(actions.updateRecommendContentsList([], ""));
    }, [dispatch]);

    /**
     * 追加ID or LicenseID検索API 成功.
     */
    const scrollIdSearchSuccess = (data) => {
        // 連続検索用.
        setIsSearch(true);

        // 追加検索表示件数を増やす.
        setRowCount(rowCount + contentif.ROW_DEFAULT);

        // リストに追加.
        if (data.length > 0) {
            let list = [];
            for (let i = 0; i < contentsList.length; i++) list.push(contentsList[i]);
            for (let i = 0; i < data.length; i++) list.push(data[i]);
            dispatch(actions.updateContentsList(list));
        }
    }

    /**
     * 追加検索API 成功.
     */
    const scrollSearchSuccess = (data) => {
        // 連続検索用.
        setIsSearch(true);
        // 追加検索無しにする.
        if (data.length < contentif.ROW_DEFAULT) setIsSearch(false);

        // 追加検索表示件数を増やす.
        if (data.length === contentif.ROW_DEFAULT) setRowCount(rowCount + contentif.ROW_DEFAULT);

        // リストに追加.
        if (data.length > 0) {
            let list = [];
            for (let i = 0; i < contentsList.length; i++) list.push(contentsList[i]);
            for (let i = 0; i < data.length; i++) list.push(data[i]);
            dispatch(actions.updateContentsList(list));
        }
    };

    /**
     * ID or LicenseID検索API 成功（特典）.
     */
    const giftIdSearchSuccess = useCallback((body) => {
        dispatch(actions.updateGiftContentsList(body));
    }, [dispatch]);

    /**
     * ID or LicenseID検索API 成功.
     */
    const idSearchSuccess = useCallback((body) => {
        dispatch(actions.updateContentsList(body));
    }, [dispatch]);

    /**
     * 検索API 成功.
     */
    const searchSuccess = useCallback((body) => {
        // 追加検索無しにする.
        if (body.length < contentif.ROW_DEFAULT)  setIsSearch(false);
        dispatch(actions.updateContentsList(body));
    }, [dispatch]);

    /**
     * レコメンドリクエストAPI 成功.
     */
    const searchRecommendSuccess = useCallback((body) => {
        setIsSearch(false);
        const contentList = body[recommendif.CONTENT_LIST];
        const clickData = body[recommendif.CLICK_DATA];

        dispatch(actions.updateRecommendContentsList(contentList, clickData));
    }, [dispatch]);

    /**
     * 定義ファイル取得 成功.
     */
    const getDefinitionFileSuccess = useCallback((body) => {
        // 配信期間内のコンテンツIDを取得.
        let libcont = [];
        for (const data of body) {
            const now = parseInt(new Date().getTime() / 1000);
            if (now > data[contentsKey.APPLY_START_DATE] && now < data[contentsKey.APPLY_END_DATE]) {
                libcont.push(data[contentsKey.CRID]);
            }
        }
        if (libcont.length === 0) {
            searchSuccess([]);
            return;
        }
        setLibContents(libcont);

        // ID検索.
        let ids = [];
        let length = (libcont.length > contentif.ROW_DEFAULT) ? contentif.ROW_DEFAULT : libcont.length;
        for (let i = 0; i < length; i++) ids.push(libcont[i]);
        let request = {
            [contentif.CONTENT_ID_LIST]: ids,
            [contentif.PLATFORM]: contentif.getRequestPlatform()
        };
        contentif.postIdSearch(request).then(idSearchSuccess, requestFailure);
    }, [searchSuccess, idSearchSuccess, requestFailure]);

    /**
     * 追加検索する条件.
     */
    const addScrollJudge = () => {
        if (app.isAllApp()) {
            // フッター位置までスクロールしたら追加検索を実施.
            const footerHeight = document.querySelector("footer")?.offsetHeight;
            return footerHeight && scrollHeight() - window.scrollY - window.innerHeight <= footerHeight;
        }
        else {
            // フッター位置までスクロールしたら追加検索を実施.
            const footerHeight = document.getElementsByClassName("p-footCont")?.[0]?.offsetHeight;
            return footerHeight && scrollHeight() - window.scrollY - window.innerHeight <= footerHeight;
        }
    };

    /**
     * 追加検索.
     */
    const scrollSearch =  () => {
        if (addScrollJudge()) {
            if (isSearch && contentsList.length > 0) {
                // 連続検索防止.
                setIsSearch(false);

                // シリーズ検索.
                if (libId === libraryId.SERIES_META) {
                    let request = {
                        [contentif.SEARCH_TYPE]: contentif.OPTION,
                        [contentif.RANGE_START]: rowCount + 1,
                        [contentif.ROW]: contentif.ROW_DEFAULT,
                        [contentif.PARENT_CRID]: seriesId,
                        [contentif.PLATFORM]: contentif.getRequestPlatform()
                    };
                    contentif.postSearch(request).then(scrollSearchSuccess, requestFailure);
                }

                // プラネット検索.
                if (libId === libraryId.PLANET) {
                    let request = {
                        [contentif.SEARCH_TYPE]: contentif.OPTION,
                        [contentif.RANGE_START]: rowCount + 1,
                        [contentif.ROW]: contentif.ROW_DEFAULT,
                        [contentif.PLANET_ID]: planetContents,
                        [contentif.PLATFORM]: contentif.getRequestPlatform()
                    };
                    contentif.postSearch(request).then(scrollSearchSuccess, requestFailure);
                }

                // ID検索.
                if (libId === libraryId.FAVORITE) {
                    let ids = [];
                    let length = (favoriteList.length > (rowCount + contentif.ROW_DEFAULT)) ? contentif.ROW_DEFAULT : (favoriteList.length - rowCount);
                    if (length < 1) return;
                    for (let i = 0; i < length; i++) ids.push(favoriteList[i + rowCount]);
                    let request = {
                        [contentif.CONTENT_ID_LIST]: ids,
                        [contentif.PLATFORM]: contentif.getRequestPlatform()
                    };
                    contentif.postIdSearch(request).then(scrollIdSearchSuccess, requestFailure);
                }
                if (libId === libraryId.RESUME) {
                    let ids = [];
                    let length = (resumeList.length > (rowCount + contentif.ROW_DEFAULT)) ? contentif.ROW_DEFAULT : (resumeList.length - rowCount);
                    if (length < 1) return;
                    for (let i = 0; i < length; i++) ids.push(resumeList[i + rowCount][contentsKey.CRID]);
                    let request = {
                        [contentif.CONTENT_ID_LIST]: ids,
                        [contentif.PLATFORM]: contentif.getRequestPlatform()
                    };
                    contentif.postIdSearch(request).then(scrollIdSearchSuccess, requestFailure);
                }

                // ライブラリ検索.
                if (libraryId.isDefinitionFile(libId)) {
                    let ids = [];
                    let length = (libContents.length > (rowCount + contentif.ROW_DEFAULT)) ? contentif.ROW_DEFAULT : (libContents.length - rowCount);
                    if (length < 1) return;
                    for (let i = 0; i < length; i++) ids.push(libContents[i + rowCount]);
                    let request = {
                        [contentif.CONTENT_ID_LIST]: ids,
                        [contentif.PLATFORM]: contentif.getRequestPlatform()
                    };
                    contentif.postIdSearch(request).then(scrollIdSearchSuccess, requestFailure);
                }
            }
        }
    }

    /**
     * ID検索.
     */
    const searchId = useCallback((id) => {
        let ids = [];
        if (id === libraryId.FAVORITE) {
            let length = (favoriteList.length > contentif.ROW_DEFAULT) ? contentif.ROW_DEFAULT : favoriteList.length;
            for (let i = 0; i < length; i++) ids.push(favoriteList[i]);
        }
        if (id === libraryId.RESUME) {
            let length = (resumeList.length > contentif.ROW_DEFAULT) ? contentif.ROW_DEFAULT : resumeList.length;
            for (let i = 0; i < length; i++) ids.push(resumeList[i][contentsKey.CRID]);
        }

        if (ids.length === 0) {
            searchSuccess([]);
            return;
        }
        let request = {
            [contentif.CONTENT_ID_LIST]: ids,
            [contentif.PLATFORM]: contentif.getRequestPlatform()
        };
        contentif.postIdSearch(request).then(idSearchSuccess, requestFailure);
    }, [favoriteList, resumeList, searchSuccess, idSearchSuccess, requestFailure]);

    /**
     * ライセンスID検索
     */
    const searchLicenseId = useCallback(() => {
        // レンタル・取得一覧は追加検索なし.
        setIsSearch(false);

        // 購入一覧検索.
        if (purchaseList.length === 0) {
            idSearchSuccess([]);
        } else {
            let ids = [];
            for (let i = 0; i < purchaseList.length; i++) ids.push(purchaseList[i][contractif.LICENSE_ID]);
            let request = {
                [contentif.LICENSE_ID_LIST]: ids,
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            };
            contentif.postLicenseSearch(request).then(idSearchSuccess, requestFailure);
        }

        // 特典一覧検索.
        if (giftList.length === 0) {
            giftIdSearchSuccess([]);
        } else {
            let ids = [];
            for (let i = 0; i < giftList.length; i++) ids.push(giftList[i][contractif.LICENSE_ID]);
            let request = {
                [contentif.LICENSE_ID_LIST]: ids,
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            };
            contentif.postLicenseSearch(request).then(giftIdSearchSuccess, requestFailure);
        }
    }, [purchaseList, giftList, idSearchSuccess, giftIdSearchSuccess, requestFailure]);

    /**
     * ライブラリ定義ファイル取得.
     */
    const getDefinitionFile = useCallback((id) => {
        // ライブラリ定義ファイル取得.
        fileApi.getDefinitionFile(id).then(getDefinitionFileSuccess, requestFailure)
    }, [getDefinitionFileSuccess, requestFailure]);

    /**
     * シリーズ検索.
     */
    const searchSeries = useCallback((id) => {
        // シリーズタイトル取得.
        let requestTitle = {
            [contentif.CONTENT_ID_LIST]: [id],
            [contentif.PLATFORM]: contentif.getRequestPlatform()
        };
        contentif.postIdSearch(requestTitle).then((res) => {
            if (res.length > 0) {
                dispatch(actions.updateSeriesTitle(res[0][contentsKey.TITLE]));
            } else {
                dispatch(actions.updateSeriesTitle("シリーズ全話"));
            }
        });

        // シリーズ一覧取得.
        let request = {
            [contentif.SEARCH_TYPE]: contentif.OPTION,
            [contentif.ROW]: contentif.ROW_DEFAULT,
            [contentif.PARENT_CRID]: id,
            [contentif.PLATFORM]: contentif.getRequestPlatform()
        };
        contentif.postSearch(request).then(searchSuccess, requestFailure);
    }, [dispatch, searchSuccess, requestFailure]);


    /**
     * レコメンドを検索.
     * 遷移元TOP
     *   type: TOP, data: レコメンド枠識別ID
     * 遷移元映像詳細
     *   type: VIDEO, data: 閲覧中crid
     * 遷移元読み物詳細
     *   type: TEXT, data: 閲覧中crid
     * 遷移元TOP
     *   type: SEARCH0, data: undefined
     */
    const searchRecommend = useCallback((type, data) => {
        let request = {
            [recommendif.NUM]: recommendif.LIBRARY_RECOMMEND,
            [recommendif.RECOMMENDCOOKIE]: recommendCookie,
            [recommendif.PLATFORM]: contentif.getRequestPlatform()
        };
        if (type === libraryId.VIDEO || type === libraryId.TEXT) {
            request[recommendif.CRID] = data;
        }
        switch(type) {
            case libraryId.TOP:
                request[recommendif.SPEC] = data;
                break;
            case libraryId.VIDEO:
                request[recommendif.SPEC] = recommendConstants.getLibraryVideoSpec(memberPlan);
                break;
            case libraryId.TEXT:
                request[recommendif.SPEC] = recommendConstants.getLibraryTextSpec(memberPlan);
                break;
            default:
                request[recommendif.SPEC] = recommendConstants.getLibrarySearch0Spec(memberPlan);
        }
        recommendif.postRecommendRequest(request).then(searchRecommendSuccess, requestRecommendFailure);
    },[searchRecommendSuccess, requestRecommendFailure]);

    /**
     * プラネット検索.
     */
    const searchPlanet = useCallback((planetId) => {
        let request = {
            [contentif.SEARCH_TYPE]: contentif.OPTION,
            [contentif.ROW]: contentif.ROW_DEFAULT,
            [contentif.PLANET_ID]: planetId,
            [contentif.PLATFORM]: contentif.getRequestPlatform()
        };
        contentif.postSearch(request).then(searchSuccess, requestFailure);
    }, [searchSuccess, requestFailure]);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.libraryId());
        window.addEventListener("scroll", scrollSearch);
        return () => {
            window.removeEventListener("scroll", scrollSearch);
        }
    }, [dispatch, scrollSearch]);

    /**
     * 画面描画時に実行する処理.
     * 画面ロード時に複数回レコメンドリクエストが飛ばされる対応
     * 依存配列更新により再レンダリングが発生して複数回useEffectが実行されるため、
     * レコメンド用に別のuseEffectフックを作成。
     */
    useEffect(() => {
        const splitPathname = location.pathname.split("/");
        if (splitPathname.length === 3) {
            let id = splitPathname[splitPathname.length - 1];
            let contentId = decode(decodeURIComponent(id));
            let splitContentId = contentId.split(",");
            if (splitContentId[0] === libraryId.RECOMMEND) {
                if (!libraryId.isLibraryId(id)) {
                    setLibId(libraryId.RECOMMEND);
                    dispatch(actions.updateSeriesTitle("あなたへのおすすめ"));
                    searchRecommend(splitContentId[1], splitContentId[2]);
                }
            }
            return () => dispatch(actions.initLibraryId());
        }
    }, [dispatch, location, searchRecommend]);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        // 遷移元情報を取得.
        const splitPathname = location.pathname.split("/");
        if (splitPathname.length === 3) {
            let id = splitPathname[splitPathname.length - 1];
            if (libraryId.isLibraryId(id)) {

                // 直リンク時の対応.
                if (id === libraryId.PICKUP_PREMIUM && (memberId === "" || plan.isFree(memberPlan))) {
                    historys.historylibraryId(history, libraryId.PICKUP_FREE);
                }
                if (id === libraryId.NEW_ARRIVAL_PREMIUM && (memberId === "" || plan.isFree(memberPlan))) {
                    historys.historylibraryId(history, libraryId.NEW_ARRIVAL_FREE);
                }

                // IDとタイトルを設定.
                setLibId(id);
                dispatch(actions.updateSeriesTitle(libraryId.getTitle(id)));

                // コンテンツID取得済み（crid）.
                if (id === libraryId.FAVORITE || id === libraryId.RESUME) {
                    searchId(id);
                }

                // コンテンツID取得済み（licenseId）.
                if (id === libraryId.PURCHASE) {
                    searchLicenseId();
                }

                // 定義ファイル取得.
                if (libraryId.isDefinitionFile(id)) {
                    getDefinitionFile(id);
                }
            } else {
                // URLデコードとbase64デコードを行う.
                let contentId = decode(decodeURIComponent(id));
                let splitContentId = contentId.split(",");

                // シリーズメタからの遷移.
                if (splitContentId[0] === libraryId.SERIES_META) {
                    setLibId(libraryId.SERIES_META);
                    setSeriesId(splitContentId[1]);
                    searchSeries(splitContentId[1]);
                }
                // レコメンドは別のuseEffectで処理するため、処理スキップ。
                else if (splitContentId[0] === libraryId.RECOMMEND) {
                    return;
                }
                else if (splitContentId[0] === libraryId.PLANET) {
                    setLibId(libraryId.PLANET);
                    dispatch(actions.updateSeriesTitle(libraryId.getTitle(libraryId.PLANET)));
                    let list = [];
                    for (let i = 1; i < splitContentId.length; i++) {
                        list.push(splitContentId[i]);
                    }
                    setPlanetContents(list);
                    searchPlanet(list);
                }
                else {
                     // 不正パス遷移.
                    history.push("/default");
                }
            }
        }
        // 不正パス遷移.
        else {
            history.push("/default");
        }
        return () => dispatch(actions.initLibraryId());
    }, [dispatch, history, location, memberId, memberPlan,
        searchId, searchLicenseId, getDefinitionFile, searchSeries]);

    /**
     * head設定.
     */
    useEffect(() => {
        if (seriesTitle !== "") {
            setHeadDefault(seriesTitle + ULTRA_SUBSC);
        }
        else {
            // シリーズタイトル取得失敗時.
            setHeadDefault(LIBRARY_ID_FAILURE_TITLE);
        }
    }, [seriesTitle]);

    /**
     * アクティブの切り替え.
     */
    const handleActive = () => {
        // 要素の取得に失敗.
        if (iconGridRef.current === null || iconListRef.current === null) return;

        // アクティブの切り替え.
        if (isGrid) {
            iconListRef.current.classList.remove("is-active");
            iconGridRef.current.classList.add("is-active");
            dispatch(changeGridList(!isGrid));
        } else {
            iconGridRef.current.classList.remove("is-active");
            iconListRef.current.classList.add("is-active");
            dispatch(changeGridList(!isGrid));
        }
    }

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <section className="c-sectPage c-sectPage--library"></section>
                <div className="c-container">
                    <div className="c-sectPageLibraryTtl">
                        {location && location.state && location.state.title !== undefined &&
                        <div className="c-sectPageLibraryTtl__txt">{location.state.title}</div>
                        }
                        {location.state === undefined &&
                        <div className="c-sectPageLibraryTtl__txt">{seriesTitle}</div>
                        }
                        <div className="p-searchBtn__changeDis p-searchBtn__changeDis--library" onClick={() => handleActive()}>
                            <div className="p-searchBtn__item p-searchBtn__item--change">
                                <div className={"p-searchBtn__icon c-icon c-picIconGrid " + (isGrid ? "is-active": "")} ref={iconGridRef}></div>
                                <div className={"p-searchBtn__icon c-icon c-picIconList " + (isGrid ? "": "is-active")} ref={iconListRef}></div>
                            </div>
                        </div>
                    </div>
                    <div className="p-searchListWrap p-searchListWrap--libraryList">
                        <div className="p-searchListBox p-searchListBox--list is-show">
                            <section className="c-sect c-sect--listDis">
                                {/* リスト表示 */}
                                {!isGrid &&
                                    <span>
                                        {contentsList.length !== 0 &&
                                            <span>
                                                {libId === libraryId.PURCHASE && <Separator input={{title: "レンタル作品"}} />}
                                                <div className="p-searchList">
                                                    <ul className="p-searchList__wrap">
                                                        {Object.entries(contentsList).map(([, value]) => (
                                                            <li key={value[contentsKey.CRID]} className="p-searchList__item">
                                                                <DefaultList meta={value} isGrid={false} clickData={clickData} />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </span>
                                        }
                                        {(giftContentsList.length !== 0 && libId === libraryId.PURCHASE) &&
                                            <span>
                                                {libId === libraryId.PURCHASE && <Separator input={{title: "特典作品"}} />}
                                                <div className="p-searchList">
                                                    <ul className="p-searchList__wrap">
                                                        {Object.entries(giftContentsList).map(([, value]) => (
                                                            <li key={value[contentsKey.CRID]} className="p-searchList__item">
                                                                <DefaultList meta={value} isGrid={false} />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </span>
                                        }
                                    </span>
                                }
                                {/* グリッド表示 */}
                                {isGrid &&
                                    <span>
                                        {contentsList.length !== 0 &&
                                            <span>
                                                {libId === libraryId.PURCHASE && <Separator input={{title: "レンタル作品"}} />}
                                                <div className="p-searchListGrid">
                                                    <ul className="p-searchListGrid__inner">
                                                        {Object.entries(contentsList).map(([, value]) => (
                                                            <li key={value[contentsKey.CRID]} className="c-contCardCatList__item">
                                                                <DefaultList meta={value} isGrid={true} clickData={clickData} />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </span>
                                        }
                                        {(giftContentsList.length !== 0 && libId === libraryId.PURCHASE) &&
                                            <span>
                                                {libId === libraryId.PURCHASE && <Separator input={{title: "特典作品"}} />}
                                                <div className="p-searchListGrid">
                                                    <ul className="p-searchListGrid__inner">
                                                        {Object.entries(giftContentsList).map(([, value]) => (
                                                            <li key={value[contentsKey.CRID]} className="c-contCardCatList__item">
                                                                <DefaultList meta={value} isGrid={true} />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </span>
                                        }
                                    </span>
                                }
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LibraryId;
